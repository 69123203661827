define("discourse/plugins/discourse-adplugin/discourse/controllers/admin-plugins-house-ads-show", ["exports", "@glimmer/tracking", "@ember/controller", "@ember/object", "@ember/service", "@ember-compat/tracked-built-ins", "@ember-decorators/object", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/models/category", "I18n", "discourse/plugins/discourse-adplugin/discourse/components/modal/preview"], function (_exports, _tracking, _controller, _object, _service, _trackedBuiltIns, _object2, _ajax, _ajaxError, _category, _I18n, _preview) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class adminPluginsHouseAdsShow extends _controller.default {
    static #_ = dt7948.g(this.prototype, "router", [_service.service]);
    #router = (dt7948.i(this, "router"), void 0);
    static #_2 = dt7948.g(this.prototype, "modal", [_service.service]);
    #modal = (dt7948.i(this, "modal"), void 0);
    static #_3 = dt7948.g(this.prototype, "houseAdsController", [(0, _controller.inject)("adminPlugins.houseAds")]);
    #houseAdsController = (dt7948.i(this, "houseAdsController"), void 0);
    static #_4 = dt7948.g(this.prototype, "selectedCategories", [_tracking.tracked], function () {
      return [];
    });
    #selectedCategories = (dt7948.i(this, "selectedCategories"), void 0);
    static #_5 = dt7948.g(this.prototype, "selectedGroups", [_tracking.tracked], function () {
      return [];
    });
    #selectedGroups = (dt7948.i(this, "selectedGroups"), void 0);
    static #_6 = dt7948.g(this.prototype, "saving", [_tracking.tracked], function () {
      return false;
    });
    #saving = (dt7948.i(this, "saving"), void 0);
    static #_7 = dt7948.g(this.prototype, "savingStatus", [_tracking.tracked], function () {
      return "";
    });
    #savingStatus = (dt7948.i(this, "savingStatus"), void 0);
    static #_8 = dt7948.g(this.prototype, "buffered", [_tracking.tracked]);
    #buffered = (dt7948.i(this, "buffered"), void 0);
    modelChanged() {
      this.buffered = new _trackedBuiltIns.TrackedObject({
        ...this.model
      });
      this.selectedCategories = this.model.categories || [];
      this.selectedGroups = this.model.group_ids || [];
    }
    static #_9 = dt7948.n(this.prototype, "modelChanged", [(0, _object2.observes)("model")]);
    get disabledSave() {
      for (const key in this.buffered) {
        // we don't want to compare the categories array
        if (key !== "categories" && this.buffered[key] !== this.model[key]) {
          return false;
        }
      }
      return true;
    }
    async save() {
      if (!this.saving) {
        this.saving = true;
        this.savingStatus = _I18n.default.t("saving");
        const data = {};
        const newRecord = !this.buffered.id;
        if (!newRecord) {
          data.id = this.buffered.id;
        }
        data.name = this.buffered.name;
        data.html = this.buffered.html;
        data.visible_to_logged_in_users = this.buffered.visible_to_logged_in_users;
        data.visible_to_anons = this.buffered.visible_to_anons;
        data.category_ids = this.buffered.category_ids;
        data.group_ids = this.buffered.group_ids;
        try {
          const ajaxData = await (0, _ajax.ajax)(newRecord ? `/admin/plugins/pluginad/house_creatives` : `/admin/plugins/pluginad/house_creatives/${this.buffered.id}`, {
            type: newRecord ? "POST" : "PUT",
            data
          });
          this.savingStatus = _I18n.default.t("saved");
          const houseAds = this.houseAdsController.model;
          if (newRecord) {
            this.buffered.id = ajaxData.house_ad.id;
            if (!houseAds.includes(this.buffered)) {
              houseAds.pushObject(_object.default.create(this.buffered));
            }
            this.router.transitionTo("adminPlugins.houseAds.show", this.buffered.id);
          } else {
            houseAds.find(ad => ad.id === this.buffered.id).setProperties(this.buffered);
          }
        } catch (error) {
          (0, _ajaxError.popupAjaxError)(error);
        } finally {
          this.set("model", this.buffered);
          this.saving = false;
          this.savingStatus = "";
        }
      }
    }
    static #_10 = dt7948.n(this.prototype, "save", [_object.action]);
    setCategoryIds(categoryArray) {
      this.selectedCategories = categoryArray;
      this.buffered.category_ids = categoryArray.map(c => c.id);
      this.setCategoriesForBuffered();
    }
    static #_11 = dt7948.n(this.prototype, "setCategoryIds", [_object.action]);
    setGroupIds(groupIds) {
      this.selectedGroups = groupIds;
      this.buffered.group_ids = groupIds.map(id => id);
    }
    static #_12 = dt7948.n(this.prototype, "setGroupIds", [_object.action]);
    cancel() {
      this.buffered = new _trackedBuiltIns.TrackedObject({
        ...this.model
      });
      this.selectedCategories = this.model.categories || [];
      this.selectedGroups = this.model.group_ids || [];
      this.setCategoriesForBuffered();
    }
    static #_13 = dt7948.n(this.prototype, "cancel", [_object.action]);
    async destroy() {
      if (!this.buffered.id) {
        this.router.transitionTo("adminPlugins.houseAds.index");
        return;
      }
      try {
        await (0, _ajax.ajax)(`/admin/plugins/pluginad/house_creatives/${this.buffered.id}`, {
          type: "DELETE"
        });
        this.houseAdsController.model.removeObject(this.houseAdsController.model.findBy("id", this.buffered.id));
        this.router.transitionTo("adminPlugins.houseAds.index");
      } catch (error) {
        (0, _ajaxError.popupAjaxError)(error);
      }
    }
    static #_14 = dt7948.n(this.prototype, "destroy", [_object.action]);
    openPreview() {
      this.modal.show(_preview.default, {
        model: {
          html: this.buffered.html
        }
      });
    }
    static #_15 = dt7948.n(this.prototype, "openPreview", [_object.action]);
    setCategoriesForBuffered() {
      // we need to fetch the categories because the serializer is not being used
      // to attach the category object to the house ads
      this.buffered.categories = this.buffered.category_ids ? this.buffered.category_ids.map(categoryId => _category.default.findById(categoryId)) : [];
    }
  }
  _exports.default = adminPluginsHouseAdsShow;
});